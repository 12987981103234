<template>
  <div>
    <el-main>
      <el-form class="el-form-search" label-width="120px">
        <el-form-item label="订单编号：">
          <el-input size="small" v-model="order_sn" @input="getKeywords($event, 1)"></el-input>
        </el-form-item>
        <el-form-item label="下单人姓名：">
          <el-input size="small" v-model="name" @input="getKeywords($event, 2)"></el-input>
        </el-form-item>
        <el-form-item label="下单人手机号：">
          <el-input size="small" v-model="mobile" @input="getKeywords($event, 3)"></el-input>
        </el-form-item>
        <el-form-item label="服务名称：">
          <el-input size="small" v-model="service_name" @input="getKeywords($event, 4)"></el-input>
        </el-form-item>
        <el-form-item label="下单时间：">
          <el-date-picker
            v-model="searchForm.create_time"
            @change="val => (val ? '' : (searchForm.create_time = []))"
            size="small"
            type="daterange"
            value-format="timestamp"
            range-separator="~"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="接单人姓名：">
          <el-input size="small" v-model="artisan_name" @input="getKeywords($event, 5)"></el-input>
        </el-form-item>
        <el-form-item label=" " label-width="20px">
          <el-button type="primary" size="small" @click="search">搜索</el-button>
          <el-button plain size="small" @click="cancelSearch">清空搜索条件</el-button>
        </el-form-item>
      </el-form>
      <el-tabs type="card" v-model="searchForm.status" @tab-click="tabClick">
        <el-tab-pane name=" ">
          <span slot="label">全部订单（{{ order_num.all }}）</span>
        </el-tab-pane>
        <el-tab-pane name="10">
          <span slot="label">待付款（{{ order_num.wait_pay }}）</span>
        </el-tab-pane>
        <el-tab-pane name="20">
          <span slot="label">待接单（{{ order_num.paid }}）</span>
        </el-tab-pane>
        <el-tab-pane name="30">
          <span slot="label">进行中（{{ order_num.theway }}）</span>
        </el-tab-pane>
        <el-tab-pane name="50">
          <span slot="label">待确认（{{ order_num.wait_comfirm }}）</span>
        </el-tab-pane>
        <el-tab-pane name="60">
          <span slot="label">待签字（{{ order_num.wait_sign }}）</span>
        </el-tab-pane>
        <el-tab-pane name="70">
          <span slot="label">已完成（{{ order_num.finish }}）</span>
        </el-tab-pane>
        <el-tab-pane name="0">
          <span slot="label">已关闭（{{ order_num.close }}）</span>
        </el-tab-pane>
      </el-tabs>
      <div class="order-list">
        <table class="order-table">
          <thead>
            <tr>
              <th>服务信息</th>
              <th>定金金额</th>
              <th>定金支付方式</th>
              <th>尾款金额</th>
              <th>尾款支付方式</th>
              <th>下单联系人</th>
              <th>接单人</th>
              <th>下单时间</th>
              <th>分销佣金</th>
              <th>订单状态</th>
              <th>操作</th>
            </tr>
            <tr style="height: 10px"></tr>
          </thead>
          <tbody v-if="!list.length">
            <tr style="background: #fff">
              <td colspan="11">
                <No />
              </td>
            </tr>
          </tbody>
          <tbody v-for="(item, index) in list" :key="index">
            <tr>
              <td colspan="11">
                <div class="order-head">
                  <div class="left">
                    <span class="sn">订单编号：{{ item.order_sn }}</span>
                    <div v-if="item.type==1">
                      <el-tag type="success">平台订单</el-tag>
                    </div>
                    <div v-if="item.type==2">
                      <el-tag type="success">物业订单</el-tag>
                    </div>
                    <div v-if="item.type==3">
                      <el-tag type="success">技工订单</el-tag>
                    </div>
                    <el-tag v-else type="primary">--</el-tag>
                  </div>
                  <div class="right">
                    <el-button type="text" @click="$router.push({ path: '/service/repairOrderInfo', query: { id: item.id } })">订单详情</el-button>
                  </div>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="service-info">
                  <div class="item">
                    <el-image :src="item.service_cover_picture"></el-image>
                    <div class="service-title">
                      <p>{{ item.service_name }}</p>
                    </div>
                  </div>
                </div>
              </td>
              <td>{{ item.deposit_price}}</td>
              <td>{{ item.deposit_pay_type ==10 ? '微信支付' : item.deposit_pay_type ==20 ?  '支付宝支付' : '--' }}</td>
              <td>
                {{ item.final_pay_price }}
              </td>

              <td>{{ item.final_pay_type ==10 ? '微信支付' : item.final_pay_type ==20 ?  '支付宝支付' : '--' }}</td>

              <td>
                <p v-show="item.goods_type != 1" style="line-height: 20px">
                  {{ item.name }}
                </p>
                <p style="line-height: 20px">{{ item.mobile }}</p>
              </td>
              <td>{{ item.artisan_name ? item.artisan_name : '--' }}</td>
              <td>
                {{ getDateformat(item.create_time) }}
              </td>
              <td>--</td>
              <td>
                <span
                  :style="{
                    color: item.state == 0 || item.state == 10 || item.state == 40 ? '#409EFF' : item.state == 20 ? '#f56c6c' : '#e6a23c',
                  }"
                >
                  <span>
                    {{
                      item.status == 0
                        ? '已关闭'
                        : item.status == -10
                            ? '已关闭'
                        : item.status == -20
                            ? '已关闭'
                        : item.status == 10
                        ? '待付款'
                        : item.status == 20
                        ? '待接单'
                        : item.status == 30 || item.status == 40
                        ? '进行中'
                        : item.status == 50
                        ? '待确认'
                        : item.status == 60
                        ? '待签字'
                        : item.status == 70 || item.status == 80
                        ? '已完成'
                        : '--'
                    }}
                  </span>
                </span>
              </td>
              <td>
              <p style="margin-bottom: 5px">
                  <el-button v-if="item.status == 20" type="primary" size="mini" @click.stop="confirmReceipt(item)">派单</el-button>
                </p>
                <el-link v-if="item.status == 10" @click.stop="cancelOrder(item)" type="primary" :underline="false">取消订单</el-link>
              </td>
            </tr>
<el-dialog title="派单" :visible.sync="paidan" width="50%">
    <ul class="cardUl">
      <li>
        <div class="cardLi">
          <div class="content cardLiTab">
            <el-form ref="form" :model="form" label-width="100px" label-position="left">
              <el-form-item label="付款人：">
                <span>{{info.name}}/{{info.mobile}}</span>
              </el-form-item>
              <el-form-item label="服务时间：">
                <span>{{getDateformat(info.order_time)}}</span>
              </el-form-item>
              <el-form-item label="服务地址：">
                  <span>{{info.address_info_d.address}} {{info.address_info_d.door_no}}</span>
              </el-form-item>
              <el-form-item label="服务类型：" required="true">
                <el-select v-model="form.type" placeholder="请选择" @change="(val) => getartisan(0, val)">
                  <el-option label="平台" value="1"></el-option>
                  <el-option label="物业" value="2"></el-option>
                  <el-option label="自由" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="服务小区" required="true">
                <el-select size="small" v-model="form.classify_id" placeholder="请选择或搜索" @change="(val) => getartisan(0, val)">
                    <el-option v-for="item in community" :key="item.id" :label="item.community_name"
                        :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="技工师傅" required="true">
                <el-select size="small" v-model="form.artisan_id" placeholder="请选择或搜索">
                    <el-option v-for="item in artisan" :key="item.id" :label="item.artisan_name"
                        :value="item.id"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item class="formBtn">
                <el-button @click="paidan = false">取消</el-button>
                <el-button type="primary" @click="onSubmit">确定</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </li>
    </ul>
</el-dialog>
          </tbody>
        </table>
      </div>
      <Paging :total="total_number" :page="searchForm.page" :pageNum="searchForm.rows" @updatePageNum="updateData"></Paging>
    </el-main>
    <!-- 小卡片 -->
  </div>
</template>
<script>
import Paging from '@/components/paging';
import { getDateformat } from '@/util/getDate';
export default {
  components: {
    Paging,
  },
  data() {
    return {
      user_id:'',
      list: [],
      total_number: 0,
      order_sn: '',
      mobile: '',
      name: '',
      service_name: '',
      paidan:!1,
      order_num: {},
      info:{},
      artisan:[],
      community: [],
      searchForm: {
        page: 1,
        rows: 10,
        order_sn: '',
        name: '',
        create_time: [],
        mobile: '',
        payment_code: '',
        order_type: 'whole',
        goods_type: '',
        state_type: 'all',
        status: '',
      },
      form: {},
    };
  },
  created() {
    this.user_id = this.$route.query.user_id;
    this.getList();
  },
  methods: {
    toDetail() {
      this.$router.push('/service/repairOrderInfo');
    },
    tabClick() {
      this.searchForm.page = 1;
      this.searchForm.rows = 10;
      this.getList(1);
    },
    search() {
      this.searchForm.page = 1;

      this.getList();
    },
      // 获取服务分类
    getCommunityList () {
        this.$axios.post(this.$api.repair.service.communityList).then(res => {
            if (res.code === 0) {
                this.community = res.result.list
            }
        });
    },
    getartisan(status, val){
        console.log(status)
        console.log(val)
        let data = this.form
        console.log(data)
        data.page = 1;
        data.rows = 10000;
        this.$axios.post(this.$api.repair.service.artisanList,data).then(res => {
            if (res.code === 0) {
                this.artisan = res.result.list
            }
        });
    },
    onSubmit(){
        if(!this.form.artisan_id) return
        let data = {
            id:this.info.id,
            artisan_id:this.form.artisan_id
        }
        this.$axios.post(this.$api.repair.service.orderDispatch,data).then(res => {
            if (res.code === 0) {
                this.community = res.result.list
                this.getList()
                this.paidan = false
            }
        });
    },
    cancelSearch() {
      this.order_sn = '';
      this.mobile = '';
      this.name = '';
      this.service_name = '';
      this.searchForm = {
        page: 1,
        rows: 10,
        order_sn: '',
        name: '',
        mobile: '',
        service_name: '',
        payment_code: '',
        order_type: 'whole',
        goods_type: '',
        state_type: 'all',
        start_time:'',
        end_time:'',
        status:'',
      };
      this.getList();
    },
    getDateformat(val) {
      return getDateformat(val);
    },
    updateData(val, status) {
      if (status == 0) {
        this.searchForm.rows = val;
      } else {
        this.searchForm.page = val;
      }
      this.getList();
    },
    getKeywords(val, type) {
      let searchForm = this.searchForm;
      switch (type) {
        case 1:
          // this.name = '';
          // this.mobile = '';
          // this.service_name = '';
          searchForm.keywords_type = 'order_sn';
          searchForm.order_sn = val;
          break;
        case 2:
          // this.mobile = '';
          // this.order_sn = '';
          // this.service_name = '';
          searchForm.keywords_type = 'name';
          searchForm.name = val;
          break;
        case 3:
          // this.order_sn = '';
          // this.name = '';
          // this.service_name = '';
          searchForm.keywords_type = 'mobile';
          searchForm.mobile = val;
          break;
        case 4:
          // this.order_sn = '';
          // this.name = '';
          // this.mobile = '';
          searchForm.keywords_type = 'service_name';
          searchForm.service_name = val;
          break;
      }
      searchForm.keywords = val;
    },
    confirmReceipt (item){
      this.info = item
          this.paidan=true;
          this.getCommunityList();
    },
    cancelOrder(item) {
        let data={id:item.id,};
        this.$axios.post(this.$api.repair.service.cancleOrder, data).then(res => {
            if (res.code == 0) {
                this.getList();
            } else {
                this.$message.error(res.msg);
            }
        });
    },
    getList(is_tab) {
      let searchForm = Object.assign(this.searchForm);
      let data = {
        page: searchForm.page,
        rows: searchForm.rows,
        status: searchForm.status,
      };
      // if (searchForm.keywords) {
      //   data.keywords = searchForm.keywords;
      //   data.keywords_type = searchForm.keywords_type;
      // }
      // if (searchForm.from) {
      //   data.from = searchForm.from;
      // }
      // if (searchForm.payment_code) {
      //   data.payment_code = searchForm.payment_code;
      // }
      if (searchForm.name) {
        data.name = searchForm.name;
      }
      if (searchForm.mobile) {
        data.mobile = searchForm.mobile;
      }
      if (searchForm.order_sn) {
        data.order_sn = searchForm.order_sn;
      }
      // if (searchForm.order_type != 'whole') {
      //   data.order_type = searchForm.order_type;
      // }
      if (searchForm.create_time.length) {
        data.start_time = searchForm.create_time[0] / 1000;
        data.end_time = searchForm.create_time[1] / 1000;
      }
      if( this.user_id ){
        data.user_id = this.user_id
      }
      this.$axios.post(this.$api.repair.service.orderList, data).then(res => {
        if (res.code == 0) {
          let list = res.result.list;
          this.list = list;
          this.total_number = res.result.total;
          this.info = this.list[0]
        } else {
          this.$message.error(res.msg);
        }
      });
      if (!is_tab) {
        this.$axios.post(this.$api.repair.service.getNum, data).then(res => {
          if (res.code == 0) {
            this.order_num = res.result;
          } else {
            this.$message.error(res.msg);
          }
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.el-main {
  background: #fff;
  .order-table {
    width: 100%;
    font-size: 14px;
    tbody {
      border: 1px solid #ebeef5;
    }
    thead tr th {
      padding: 15px;
      text-align: center;
    }
    thead tr {
      background: #f8f9fa;
      th:first-child {
        width: 280px;
      }
      th:last-child {
        width: 150px;
      }
    }
    tbody tr:first-child {
      border-bottom: 1px solid #ebeef5;

      background: #f8f9fa;
      td {
        padding: 0 20px;
      }
    }
    tbody tr:last-child {
      td {
        text-align: center;
      }
      td:first-child {
        width: 280px;
      }
      td:last-child {
        width: 150px;
      }
    }
    .service-info {
      width: 300px;
      flex: none;
      padding-left: 20px;

      .item {
        height: 70px;
        display: flex;
        align-items: center;
        .el-image {
          width: 50px;
          height: 50px;
          margin-right: 10px;
          border-radius: 5px;
        }
        .service-title {
          text-align: left;
          flex: 1;
          p:nth-child(1) {
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
            font-size: 13px;
            line-height: 18px;
          }
        }
      }
    }
    .order-head {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .left {
        display: flex;
        align-items: center;
        .sn {
          margin-right: 70px;
        }
        div {
          display: flex;
          align-items: center;
        }
        .el-button {
          cursor: auto;
        }
      }
    }
  }
}

.cardUl {
  width: 100%;
  background-color: #fff;


  justify-content: space-between;
  flex-wrap: wrap;

  li {
    display: flex;
    justify-content: space-between;

    span {
      font-size: 14px;
      color: gray;
    }
  }

  .cardLi {
    width: 80%;
    position: relative;


    .cardLiTil {
      background-color: #f0eeee;
      color: gray;
      font-size: 18px;
      height: 50px;
      line-height: 50px;

      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .cardLiTab {
      padding: 0px 50px;
    }

    .cardTabPage {
      display: flex;
      justify-content: center;

      position: absolute;
      bottom: 20px;
      left: 40%;
    }
  }
}
</style>
